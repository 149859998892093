.card-img {
  width: 150px;
}

.title-card {
  font-weight: 800;
  color: #fff;
  margin-top: 30px;
  font-size: 20px;
  margin-bottom: 6px;
}

.paragraph-card {
  color: #fff;
}

.content-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
}

@media (max-width: 1470px) {
  .content-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .content-cards {
    display: contents;
    grid-template-columns: 1fr;
  }

  .content-cards>div>img {
    height: 200px;
    width: 200px;
  }
}

.zoom-image {
  display: inline-block;
}

/* Aplica la animación solo al poner el puntero sobre la imagen */
.zoom-image:hover {
  transform: scale(1.2);
}