.goals-img {
  width: 150px;
}
.content-cards--goals {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
}

.title-card-dark {
  font-weight: 800;
  color: #333;
  margin-top: 30px;
  font-size: 20px;
  margin-bottom: 6px;
}

.paragraph-card-dark {
  color: #333;
}

@media (max-width: 745px) {
  .content-cards--goals {
    display: contents;
    grid-template-columns: 1fr;
  }
}
