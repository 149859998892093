.img-modules {
    width: 100%;
    border-radius: 10px;
}

.list-modules {
    list-style: none;
    padding: 0;
}

.paragraph-modules {
    margin-top: 20px;
    font-weight: 600;
}