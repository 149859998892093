.carousel-caption {
  width: 30%;
  bottom: 20%;
  left: 7%;
}

@media (max-width: 1024px) {
  .carousel-caption {
    bottom: 0;
  }
}

@media (max-width: 768px) {
  .carousel-caption {
    width: 80%;
    bottom: 5%;
  }

  .carousel-item {
    background: #000;
  }

  .carousel-item img {
    opacity: 0.7;
  }
}

@media (max-width: 425px) {
  .carousel-item {
    background: #000;
  }

  .carousel-item img {
    opacity: 0.6;
  }

  .carousel-caption {
    width: 80%;
  }

  .carousel-caption h3 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .carousel-caption p {
    font-size: 10px;
  }

  .button-slider {
    font-size: 12px;
  }

  .slider-img {
    height: 250px;
    width: 750px !important;
  }
}

.button-slider {
  font-family: "TitanOne-Regular";
  color: #7b087d;
  background: #fff;
  border: none;
  border-radius: 20px;
}

.slider-img {
  scale: 1.5;
}

.btn-primary:hover {
  background-color: #ac5ea0 !important;
  color: #7b087d !important;
}

.btn-primary {
  background-color: #fff !important;
  color: #7b087d !important;
}