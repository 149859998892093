.custom-shape-divider-top-1719448039 {
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    margin-top: -1px;
}

.custom-shape-divider-top-1719448039 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 80px;
}

.custom-shape-divider-top-1719448039 .shape-fill {
    fill: #FFFFFF;
}

.img-prize {
    margin-bottom: 40px;
}

.custom-shape-divider-top-1719448039 .color-violet {
    fill: #7b087d;
}

.clouds-down {
    transform: rotate(180deg);
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: -1px auto;
}

.welcome-video video {
    border-radius: 20px;
    box-shadow: 0 2px 10px rgb(0 0 0 / 50%);
    border: 2px solid #7b087d;
}



@media (max-width: 1250px) {
    .carousel_tool {
        opacity: 1 !important;
        width: 100%;
    }

    .carousel-item_tool {
        background: #FFF;
    }
}