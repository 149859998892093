@font-face {
  font-family: TitanOne-Regular;
  src: url("./assets/fonts/TitanOne-Regular.woff");
  font-display: swap;
}

@font-face {
  font-family: OpenSans-normal;
  src: url("./assets/fonts/OpenSans-normal.woff");
  font-display: swap;
}

@font-face {
  font-family: Nunito;
  src: url("./assets/fonts/Nunito-ExtraLight.woff");
  font-display: swap;
}

body {
  font-family: "OpenSans-normal";
  font-size: 16px;
  color: #333;
}

.title {
  font-family: "TitanOne-Regular";
  font-size: 34px;
  text-align: left;
  margin-bottom: 30px;
}

.paragraph {
  font-size: 16px;
  text-align: left;
  word-wrap: break-word;
}

.welcome-container {
  display: flex;
  gap: 10%;
  padding: 50px 120px 40px 120px;
  align-items: center;
}

.welcome-text {
  text-align: justify;
  color: #333;
}

.container-cards {
  background-color: #7b087d;
  padding: 100px 100px 50px 100px;
  color: #FFF;
}

.container-mission {
  background-color: #fce5ec;
  display: flex;
  gap: 10%;
  padding: 50px 150px;
  justify-content: center;
  align-items: center;
}

.container-modules {
  padding: 50px 150px;
}

.container-modules video {
  width: 70%;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgb(0 0 0 / 50%);
  border: 2px solid #7b087d;
}

.container-goals {
  background-color: #FCE5EC;
  padding: 70px 150px 50px 150px;
  color: #333;
}

.container-class {
  background-color: #FFF;
  padding: 50px 150px;
}

.container-writing {
  padding: 50px 150px;
  background-color: #FCE5EC;
  color: #333;
}

.container-writing img {
  width: 400px;
}

.container-supp {
  background-color: #7b087d;
  padding: 100px 150px;
  color: #FFF
}

.container-info {
  background-color: #FEF18D;
  padding: 20px;
}

.footer {
  background-color: #FAE051;
  padding: 30px;
  font-weight: 800;
  text-align: center;
}

.clouds {
  width: 100%;
  position: absolute;
  top: 93%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.container-slider {
  position: relative;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.tc {
  text-align: center;
}

.mb-20 {
  margin-bottom: 50px;
}

.btn-goclass {
  font-family: 'TitanOne-Regular';
  border: none;
  border-radius: 20px;
  width: 10em;
  height: 3em;
}

.container-img-network {
  gap: 10px;
  display: flex;
  justify-content: center;
}

.container-img-network img {
  width: 35px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-2 {
  margin-bottom: 2px;
}

.tc {
  text-align: center;
}

.mb-5 {
  margin-bottom: 50px;
}

.center-container {
  display: flex;
  align-items: center;
  text-align: justify;
}

.container-divider {
  background-color: #7b087d;
  height: 5px;
}

@media (max-width: 425px) {
  .container-writing img {
    width: 100%;
  }

  .clouds {
    top: 96%;
  }

  .container-modules video {
    width: 100%;
  }

  .welcome-video video {
    width: 100% !important
  }
}

@media (max-width: 768px) {
  .container-modules video {
    width: 100%;
  }

  .welcome-container {
    flex-direction: column;
    padding: 50px 20px;
  }

  .container-cards {
    padding: 50px 20px;
  }

  .container-mission {
    flex-direction: column;
    padding: 50px 20px;
  }

  .container-modules {
    padding: 50px 20px;
  }

  .container-goals {
    padding: 50px 20px;
  }

  .container-class {
    padding: 50px 20px;
  }

  .container-writing {
    padding: 50px 20px;
  }

  .container-writing p {
    word-wrap: break-word;
  }

  .container-supp {
    padding: 50px 20px;
  }

  .w100 {
    width: 100%;
  }
}

.btn-goclass.btn.btn-primary:hover {
  background-color: #AC5EA0 !important;
  color: #7B087D !important;
}

.btn-goclass.btn.btn-primary {
  background-color: #7B087D !important;
  color: #FFF !important;
}