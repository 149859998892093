.container--calendar > .container {
  margin-top: 100px;
}

.title--calendar {
  font-family: "TitanOne-Regular";
}

.fc-scrollgrid-sync-inner > a {
  color: #8d2680;
}

.fc-daygrid-day-top > a {
  color: #8d2680;
}

.fc-prev-button {
  background: #fac61a !important;
}

.fc-next-button {
  background: #fac61a !important;
}

.fc-daygrid-event {
  background-color: #8d2680;
  border: 1px solid #8d2680;
}

.container--full-calendar {
  margin: 30px 0px 60px 0px;
}
